import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  statusList: [],
  statusMapping: [
    {
      processType: feedbackEnums.processTypes.MAFEG,
      mapping: {
        0: [100],
        10: [110, 120, 130, 140],
        20: [150, 160],
        99: [170, 199]
      }
    },
    {
      processType: feedbackEnums.processTypes.PDP,
      mapping: { 0: [0], 110: [110], 120: [120], 199: [199] }
    },
    {
      processType: feedbackEnums.processTypes.EHG,
      mapping: { 0: [200], 10: [210, 220], 20: [230, 240], 99: [250, 260, 299] }
    },
    {
      processType: feedbackEnums.processTypes.PDC,
      mapping: { 0: [300], 10: [310], 99: [320, 330, 340] }
    },
    {
      processType: feedbackEnums.processTypes.EDC,
      mapping: { 0: [500], 10: [510], 99: [520] }
    }
  ],
  allRoles: ['10', '20', '1000'],
  allFeedbackTypes: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '100',
    '200'
  ],
  allConclusionTypes: ['1', '2'],
  processCreator: [
    { processType: feedbackEnums.processTypes.EHG, creators: [10, 20, 1000] },
    { processType: feedbackEnums.processTypes.MAFEG, creators: [10, 20, 1000] }
  ]
};
